import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../actions/authSlice';
import { authApi } from './authService';
import jwtDecode from 'jwt-decode';
import AuthMiddleware from './authMiddleware';

const loadToken = () => {
	try {
		const userToken = localStorage.getItem('userToken');
		if (userToken === null) {
			return null;
		}

		const userInfo = jwtDecode(userToken);

		return {
			userToken,
			userInfo,
		};
	} catch (e) {
		return null;
	}
};

const store = configureStore({
	reducer: {
		auth: authReducer,
		[authApi.reducerPath]: authApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(AuthMiddleware).concat(authApi.middleware),
	preloadedState: {
		auth: { ...loadToken() },
	},
});
export default store;
