import React, { useEffect } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ResetPasswordContainer, InputWrapper, Label, Button } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../components/Logo';
import { useResetPasswordMutation } from '../../store/authService';
import jwtDecode from 'jwt-decode';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

const ResetPassword = () => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
	} = useForm();
	const navigate = useNavigate();

	const password = React.useRef({});
	password.current = watch('password', '');
	let { tokenURL } = useParams();
	const val = jwtDecode(tokenURL);
	const [resetPassword, { data, isLoading, isSuccess, is }] = useResetPasswordMutation();

	useEffect(() => {
		console.log('success');
	}, [isSuccess, isLoading]);

	const submitForm = (data) => {
		resetPassword({ ...data, email: val.email, resetToken: val.resetToken });
		reset();
		navigate('/login');
	};

	return (
		<ResetPasswordContainer>
			<Logo color="#343a40" size="2rem" />
			<p className="mt-5">Şifrenizi aşağıdaki alana girerek yeni şifre oluşturabilirsiniz.</p>

			{errors.password && <p>{errors.password.message}</p>}
			{errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
			<form onSubmit={handleSubmit(submitForm)} className="mt-5">
				<InputWrapper>
					<Label>Yeni Şifre</Label>
					<input
						type="password"
						{...register('password', {
							required: 'Lütfen şifre alanlarını doldurunuz.',
							minLength: {
								value: 8,
								message: 'Şifreniz en az 8 karakter uzunluğunda olmalıdır',
							},
						})}
					/>
				</InputWrapper>
				<InputWrapper>
					<Label>Yeni Şifre Tekrar</Label>
					<input
						type="password"
						{...register('confirmPassword', {
							validate: (value) => value === password.current || 'Girdiğiniz şifreler eşleşmiyor',
						})}
					/>
				</InputWrapper>
				<Button type="submit">
					{isLoading && (
						<Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
							className="me-4"
						/>
					)}
					{isLoading ? 'Kaydediliyor' : 'Kaydet'}
				</Button>
			</form>
			<div className="mt-4">
				<NavLink to="/login">Giriş ekranına geri dön</NavLink>
			</div>
		</ResetPasswordContainer>
	);
};

export default ResetPassword;
