import styled from 'styled-components';

const LogoWrapper = styled.div`
	color: #fff;
	font-size: 130%;
	margin-top: 1.8rem;
	font-weight: bold;
	font-family: 'Poppins-bold';
	line-height: 15px;
	text-align: center;

	span {
		display: block;
		font-family: 'Poppins-regular';
		font-size: 0.8rem;
		font-weight: 300;
		letter-spacing: 5px;
	}
`;

export { LogoWrapper };
