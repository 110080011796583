import React from 'react';
import { LogoWrapper } from './styled';
const Logo = (props) => {
	return (
		<LogoWrapper style={{ color: props.color, fontSize: props.size }}>
			KEYOFCARBON <span>sustainability</span>
		</LogoWrapper>
	);
};

export default Logo;
