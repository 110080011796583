import React from 'react';
import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import { useGetCompanyQuery, useUpdateCompanyMutation } from '../../store/authService';

const FirmScreen = () => {
	const { data, isLoading, isSuccess } = useGetCompanyQuery();

	const [updateCompany, result] = useUpdateCompanyMutation();

	const [firmInfo, setFirmInfo] = useState({
		address: '',
		phone: '',
		email: '',
		city: '',
		town: '',
		country: '',
		workers_count: '',
		fee: '',
		product_count: '',
		id: '',
	});

	useEffect(() => {
		setFirmInfo((prev) => ({
			...prev,
			address: data?.company.address,
			phone: data?.company.phone,
			email: data?.company.email,
			city: data?.company.city,
			town: data?.company.town,
			country: data?.company.country,
			workers_count: data?.company.workers_count,
			fee: data?.company.fee,
			product_count: data?.company.product_count,
			id: data?.company.id,
		}));
	}, [data]);

	const updateField = (e) => {
		setFirmInfo((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		updateCompany(firmInfo);
	};

	return (
		<Row>
			<Col lg={12} xl={12} className="mb-4">
				<Card>
					<Card.Header>Firma Bilgileri</Card.Header>
					<Card.Body>
						{isLoading && (
							<div className="d-flex align-items-center justify-content-center">
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
									className="me-4"
								/>
							</div>
						)}
						{isSuccess && data && (
							<Form onSubmit={handleSubmit}>
								<Row>
									<Form.Group as={Col} className="my-3" controlId="">
										<Form.Label>Firma Adı</Form.Label>
										<Form.Control size="sm" defaultValue={data.company.name} />
									</Form.Group>
									<Form.Group as={Col} className="my-3" controlId="">
										<Form.Label>Telefon</Form.Label>
										<Form.Control
											name="phone"
											size="sm"
											defaultValue={data.company.phone}
											value={firmInfo.phone}
											onChange={updateField}
										/>
									</Form.Group>
									<Form.Group as={Col} className="my-3" controlId="">
										<Form.Label>Adres</Form.Label>
										<Form.Control
											name="address"
											size="sm"
											defaultValue={data.company.address}
											value={firmInfo.address}
											onChange={updateField}
										/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group as={Col} className="my-3" controlId="">
										<Form.Label>E-Posta</Form.Label>
										<Form.Control
											name="email"
											size="sm"
											defaultValue={data.company.email}
											value={firmInfo.email}
											onChange={updateField}
										/>
									</Form.Group>
									<Form.Group as={Col} className="my-3" controlId="">
										<Form.Label>İl</Form.Label>
										<Form.Control
											name="city"
											size="sm"
											defaultValue={data.company.city}
											value={firmInfo.city}
											onChange={updateField}
										/>
									</Form.Group>
									<Form.Group as={Col} className="my-3" controlId="">
										<Form.Label>İlçe</Form.Label>
										<Form.Control
											name="town"
											size="sm"
											defaultValue={data.company.town}
											value={firmInfo.town}
											onChange={updateField}
										/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group as={Col} className="my-3" controlId="">
										<Form.Label>Çalışan Sayısı</Form.Label>
										<Form.Control
											size="sm"
											defaultValue={data.company.workers_count}
											name="workers_count"
											value={firmInfo.workers_count}
											onChange={updateField}
										/>
									</Form.Group>
									<Form.Group as={Col} className="my-3" controlId="">
										<Form.Label>Üretim Kapasitesi</Form.Label>
										<Form.Control
											size="sm"
											defaultValue={data.company.product_count}
											name="product_count"
											value={firmInfo.product_count}
											onChange={updateField}
										/>
									</Form.Group>
									<Form.Group as={Col} className="my-3" controlId="">
										<Form.Label>Endüstri</Form.Label>
										<Form.Control
											size="sm"
											defaultValue={data.company.industry_type}
											name="industry_type"
											value={firmInfo.industry_type}
											onChange={updateField}
										/>
									</Form.Group>
								</Row>
								<Button variant="success" type="submit">
									{result.isLoading && (
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
											className="me-4"
										/>
									)}
									{/* {result.isLoading ? "Güncelleniyor" : "Güncelle"} */}
									Güncelle
								</Button>
							</Form>
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default FirmScreen;
