import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Card, Form, ListGroup, Tab, Col, Row } from 'react-bootstrap';
import jwtDecode from 'jwt-decode';
import Lottie from 'lottie-react';
import factoryLottie from '../lotties/factory.json';
import { useSelector } from 'react-redux';
import Chart from 'chart.js/auto';
import { ArcElement } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { useGetEmissonResultForChartQuery } from '../store/authService';
import { roundNumber } from '../utils';
import { PiFactory } from 'react-icons/pi';
import { MdOutlineEnergySavingsLeaf } from 'react-icons/md';
import { RiFlightTakeoffFill } from 'react-icons/ri';
Chart.register(ArcElement);

const HomeScreen = () => {
	const navigate = useNavigate();
	const [factory, setFactory] = useState(null);
	const [activeChart, setActiveChart] = useState('scope_one');

	const { userToken } = useSelector((state) => state.auth);
	const { data: forChart, isLoading } = useGetEmissonResultForChartQuery();

	useEffect(() => {
		return setFactory(jwtDecode(userToken).company);
	}, [userToken]);

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: factoryLottie,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	const handleGetChart = (val) => {
		console.log(forChart);
		setActiveChart(val);
	};

	const chart = useMemo(() => {
		if (forChart === undefined) return { label: [], data: [] };
		return forChart.result
			?.filter((item) => item[activeChart] !== 0)
			.reduce(
				(acc, cur) => {
					acc.label.push(cur.emission_source);
					acc.data.push(cur[activeChart]);
					return acc;
				},
				{
					label: [],
					data: [],
				},
			);
	}, [forChart, activeChart]);

	const totalVal = useMemo(() => {
		if (forChart === undefined) return 0;
		return forChart.result.reduce(
			(acc, cur) => (acc += cur.scope_one + cur.scope_two + cur.scope_three),
			0,
		);
	}, [forChart]);

	return (
		<>
			{factory &&
				factory?.map((value, index) => (
					<React.Fragment key={value.id}>
						<Col lg={5}>
							<Row>
								<Col>
									<Card>
										<Lottie options={defaultOptions} height={200} width={400} />
										<Card.Body>
											<Card.Title>{value.name} Fabrika</Card.Title>
											<Card.Text>Merkez</Card.Text>
											<Button variant="success" size="sm" href="/analysis">
												Analizi Göster
											</Button>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Col>
						<Col lg={7}>
							<Card>
								<Card.Header>Lokasyon Bilgileri</Card.Header>
								<Card.Body>
									<Form>
										<Row className="mb-4">
											<Form.Group as={Col}>
												<Form.Label>Ülke</Form.Label>
												<Form.Control placeholder={value.country} disabled />
											</Form.Group>
											<Form.Group as={Col}>
												<Form.Label>Şehir</Form.Label>
												<Form.Control placeholder={value.city} disabled />
											</Form.Group>
										</Row>
										<Row className="mb-4">
											<Form.Group as={Col}>
												<Form.Label>İlçe</Form.Label>
												<Form.Control placeholder={value.town} disabled />
											</Form.Group>
											<Form.Group as={Col}>
												<Form.Label>Çalışan Sayısı</Form.Label>
												<Form.Control placeholder={value.workers_count} disabled />
											</Form.Group>
										</Row>
										<Row className="mb-4">
											<Form.Group as={Col}>
												<Form.Label>Ciro</Form.Label>
												<Form.Control placeholder={value.fee} disabled />
											</Form.Group>
											<Form.Group as={Col}>
												<Form.Label>Ürün Sayısı</Form.Label>
												<Form.Control placeholder={value.product_count} disabled />
											</Form.Group>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</React.Fragment>
				))}

			<Col lg={12} className="mt-5">
				<Card>
					<Card.Header>Raporlar</Card.Header>
					<Card.Body>
						<Card.Title> Gösterilecek bir rapor bulunmuyor.</Card.Title>
						<Card.Text>
							Bu loaksyon için oluşturulmuş bir rapor mevcut değil hızlıca oluşturabilirsin.{' '}
						</Card.Text>
						<Button variant="danger" size="sm" onClick={() => navigate('/reports')}>
							Oluştur
						</Button>
					</Card.Body>
				</Card>
			</Col>

			<Col className="my-5">
				<Card>
					<Card.Header>Analiz</Card.Header>
					<Card.Body>
						<Card.Title>İstanbul Lokasyonu</Card.Title>
						<Card.Text>Kapsam seçenekli rapor aşağıdaki gibidir.</Card.Text>
						<Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
							<Row>
								<Col sm={5}>
									<ListGroup>
										<ListGroup.Item
											as="li"
											className="fw-bold"
											style={{ fontWeight: 'bold', cursor: 'pointer' }}
											onClick={() => handleGetChart('scope_one')}
										>
											<PiFactory className="me-3 fs-3" />
											Kapsam 1
										</ListGroup.Item>
										<ListGroup.Item
											as="li"
											style={{ fontWeight: 'bold', cursor: 'pointer' }}
											onClick={() => handleGetChart('scope_two')}
										>
											<MdOutlineEnergySavingsLeaf className="me-3 fs-3" />
											Kapsam 2
										</ListGroup.Item>
										<ListGroup.Item
											disabled
											as="li"
											style={{ fontWeight: 'bold', cursor: 'pointer' }}
											onClick={() => handleGetChart('scope_three')}
										>
											<RiFlightTakeoffFill className="me-3 fs-3" />
											Kapsam 3
										</ListGroup.Item>
									</ListGroup>
								</Col>
								<Col sm={5} className="d-flex justify-content-center">
									{/* Pie chart gelecek */}
									{!isLoading && (
										<Pie
											data={{
												labels: chart.label,
												datasets: [
													{
														label: 'Toplam Emisyon',
														data: chart.data,
													},
												],
											}}
										/>
									)}
								</Col>
								<Col sm={12} className="mt-4">
									<p className="h5 text-center bg-secondary text-white p-2 mb-5">
										Toplam Emisyon Dağılımı : {roundNumber(totalVal)} Ton (C02)
									</p>
								</Col>
							</Row>
						</Tab.Container>
					</Card.Body>
				</Card>
			</Col>
		</>
	);
};

export default HomeScreen;
