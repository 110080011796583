import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { async } from 'q';

//const backendURL = 'http://localhost:3000';
const backendURL = 'https://carbon-backend-6eo4.onrender.com';

export const userLogin = createAsyncThunk(
	'login',
	async ({ email, password }, { rejectWithValue }) => {
		try {
			// configure header's Content-Type as JSON
			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			const { data } = await axios.post(`${backendURL}/login`, { email, password }, config);

			// store user's token in local storage
			localStorage.setItem('userToken', data.token);

			return data;
		} catch (error) {
			// return custom error message from API if any
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	},
);

export const registerUser = createAsyncThunk(
	'auth/register',
	async ({ firstName, email, password }, { rejectWithValue }) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			await axios.post(`${backendURL}/api/user/register`, { firstName, email, password }, config);
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	},
);

export const forgotPassword = createAsyncThunk(
	'forgot-password',
	async ({ email }, { rejectWithValue }) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			const { data } = await axios.post(`${backendURL}/forgot-password`, { email }, config);
			return data;
		} catch (error) {
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message);
			} else {
				return rejectWithValue(error.message);
			}
		}
	},
);
