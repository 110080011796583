import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, Alert, Modal } from 'react-bootstrap';
import { useDeleteResultMutation, useGetEmissonResultQuery } from '../../store/authService';
import Spinner from 'react-bootstrap/Spinner';
import { SpinnerContainer } from './styled';
import moment from 'moment';
import { roundNumber } from '../../utils';

const ReportTable = ({ scope, scopeName, tableId }) => {
	const { data, refetch, isLoading } = useGetEmissonResultQuery(
		{ source_id: tableId },
		{ skip: !scopeName },
	);
	const [deleteResult, { isSuccess, isLoading: deleting }] = useDeleteResultMutation();
	const [showModal, setShowModal] = useState();
	const [imagePath, setImagePath] = useState();
	const [imageModal, setImageModal] = useState();

	useEffect(() => {
		if (scopeName && data) {
			refetch();
		}
	}, [scopeName]);

	const handleDelete = async (id) => {
		console.log('remove');
		await deleteResult(id);
		handleClose();
		refetch();
	};

	const handleClose = () => {
		setShowModal(undefined);
		setImageModal(undefined);
	};

	const handleShowImage = (val) => {
		setImagePath(val);
		setImageModal(true);
	};

	return (
		<Row className="mt-5">
			{isLoading && (
				<SpinnerContainer>
					<Spinner as="span" animation="border" size="sm" role="status" className="me-4" />
				</SpinnerContainer>
			)}
			<Col md={12}>
				<p
					style={{ backgroundColor: '#343a40' }}
					className="h6 text-center text-white p-2 rounded-1"
				>
					Kapsam {scope} Raporu
				</p>

				{data && data.result.length > 0 ? (
					<Table responsive="md" className="table table-striped table-hover">
						<thead>
							<tr>
								<th scope="col">Emisyon kaynağı</th>
								<th scope="col">Miktar</th>
								<th scope="col">Birim</th>
								<th scope="col">tCO2</th>
								<th scope="col">Tarih</th>
								<th>Periyod</th>
								<th scope="col">Açıklama</th>
								<th scope="col">Fatura</th>
								<th scope="col">Aksiyon</th>
							</tr>
						</thead>
						<tbody>
							{data.result.map((value, index) => {
								return (
									<tr key={value.id}>
										<td>{value.emission_source}</td>
										<td>{value.consumption_amount}</td>
										<td>{value.unit}</td>
										<td>{roundNumber(value.carbon_footprint)}</td>
										<td>{moment(value.emission_date).format('MM/yyyy')}</td>
										<td>{value.period}</td>
										<td>{value.explanation}</td>
										<td>
											<Button
												variant="success"
												size="sm"
												onClick={() => handleShowImage(value.file_path)}
											>
												Görüntüle
											</Button>
										</td>
										<td>
											<Button
												onClick={() => setShowModal(value.id)}
												variant="danger"
												className="ms-1"
												size="sm"
											>
												Sil
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				) : (
					<Alert variant="info">Henüz gösterilecek bir veriniz bulunmamaktadır.</Alert>
				)}
			</Col>
			<Modal show={showModal !== undefined} centered onHide={handleClose}>
				<Modal.Body className="text-center">
					Bu veriyi silmek istediğinizden emin misiniz?
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button
						disabled={deleting}
						size="sm"
						variant="success"
						onClick={() => handleDelete(showModal)}
					>
						Sil
					</Button>
					<Button size="sm" variant="danger" onClick={handleClose}>
						Kapat
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={imageModal !== undefined} centered onHide={handleClose}>
				<Modal.Body className="text-center">
					<img src={imagePath} style={{ width: '95%' }} />
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button size="sm" variant="danger" onClick={handleClose}>
						Kapat
					</Button>
				</Modal.Footer>
			</Modal>
		</Row>
	);
};

export default ReportTable;
