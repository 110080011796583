import React, { useEffect } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ForgotPasswordContainer, InputWrapper, Label, Button } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../components/Logo';
import { forgotPassword } from '../../actions/authActions';
import { useForgotPasswordMutation } from '../../store/authService';

const ForgotPassword = () => {
	const [forgotPassword, { isLoading, isSuccess, isError }] = useForgotPasswordMutation();
	const { register, handleSubmit, reset } = useForm();
	const submitForm = (data) => {
		forgotPassword(data);
		reset();
	};

	return (
		<ForgotPasswordContainer>
			<Logo color="#343a40" size="2rem" />
			<p className="mt-5">
				Şifreminizi unuttuysanız aşağıdaki alana kayıtlı e-posta adresinizi girerek tekrar şifre
				alabilirsiniz.
			</p>
			{isSuccess && (
				<Alert variant="success">
					Şifre değiştirmeniz için gerekli link mail adresinize başarılı bir şekilde gönderilmiştir.
				</Alert>
			)}
			{isError && <Alert variant="danger">Lütfen geçerli bir mail adresi giriniz.</Alert>}
			<form onSubmit={handleSubmit(submitForm)} className="mt-5">
				<InputWrapper>
					<Label>E-Posta</Label>
					<input type="text" {...register('email')} />
				</InputWrapper>
				<Button type="submit" disabled={isLoading}>
					{isLoading && (
						<Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
							className="me-4"
						/>
					)}
					{isLoading ? 'Gönderiliyor' : 'Gönder'}
				</Button>
			</form>
			<div className="mt-4">
				<NavLink to="/login">Giriş ekranına geri dön</NavLink>
			</div>
		</ForgotPasswordContainer>
	);
};

export default ForgotPassword;
