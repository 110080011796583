import styled from 'styled-components';
const SpinnerContainer = styled.div`
	height: 100%;
	background: rgba(255, 255, 255, 0.2);
	position: absolute;
	width: 100%;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const DatePickerContainer = styled.div`
	.react-datepicker__input-container input {
		border-color: #dee2e6;
		border-radius: 4px;
		border-width: 1px;
		padding: 5px 4px;
		font-size: 13px;
		box-shadow: none;
	}
`;

export { SpinnerContainer, DatePickerContainer };
