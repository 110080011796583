import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ButtonContainer, LogoutContainer } from './styled';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/authSlice';
import Logo from '../../components/Logo';

const Logout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(logout());

		const timeout = setTimeout(() => {
			navigate('/login');
		}, 5000);

		return () => clearTimeout(timeout);
	}, [dispatch, navigate]);

	return (
		<LogoutContainer>
			<div className="unauthorized">
				<div>
					<Logo color="#343a40" size="2rem" />
				</div>
				<h4 className="my-5">
					Çıkış Yaptınız.
					<br />
					Tekrar giriş yapabilmek için aşağıdaki butona tıklayabilirsiniz
				</h4>
				<ButtonContainer>
					<NavLink to="/login">Giriş sayfasına git</NavLink>
				</ButtonContainer>
			</div>
		</LogoutContainer>
	);
};

export default Logout;
