import React from 'react';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { NavbarContainer } from './styled';
import Logo from '../Logo';
import {
	FiHome,
	FiCrosshair,
	FiSave,
	FiClipboard,
	FiArchive,
	FiUsers,
	FiInfo,
} from 'react-icons/fi';

const Navbar = () => {
	return (
		<>
			<Logo />
			<NavbarContainer>
				<Nav className="flex-column ps-3">
					<Link to="/">
						<FiHome /> Anasayfa
					</Link>
					<Link to="/location">
						<FiCrosshair /> Lokasyonlar
					</Link>
					<Link to="/calculate">
						<FiSave /> Hesaplama
					</Link>
					<Link to="/analysis">
						<FiClipboard /> Analiz
					</Link>
					<Link to="/results">
						<FiArchive /> Sonuçlar
					</Link>
					<Link to="/user">
						<FiUsers /> Kullanıcılar
					</Link>
					<Link to="/firm-info">
						<FiInfo /> Firma Bilgileri
					</Link>
				</Nav>
			</NavbarContainer>
		</>
	);
};

export default Navbar;
