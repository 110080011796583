import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
import {
	useGetEmissonResultForAnalysisQuery,
	useGetSourceTypeQuery,
} from '../../store/authService';
import Chart from 'chart.js/auto';
import { ArcElement } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { roundNumber } from '../../utils';
import moment from 'moment';
import 'moment/locale/tr';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';

Chart.register(ArcElement);
moment.locale('tr');
const months = moment.months();

const AnalysisScreen = () => {
	const { data } = useGetSourceTypeQuery();
	const [select, setSelect] = useState([]);
	const [factory, setFactory] = useState(null);
	const [year, setYear] = useState(new Date().getFullYear());
	const { userToken } = useSelector((state) => state.auth);
	const { data: { result, years } = { result: [], years: [] }, isLoading } =
		useGetEmissonResultForAnalysisQuery(
			{
				year: year === '' ? undefined : year,
			},
			{
				refetchOnMountOrArgChange: true,
			},
		);

	useEffect(() => {
		return setFactory(jwtDecode(userToken).company);
	}, [userToken]);

	useEffect(() => {
		console.log('asdf', select);
	}, [select]);

	const chart = useMemo(() => {
		let data = [];
		let label = [];

		result
			.filter((item) => select.some((id) => id === item.id))
			.forEach((item) => {
				label.push(item.emission_source);
				data.push(item.carbon_footprint);
			});

		return { label, data };
	}, [result, select]);

	const handleSelect = (val) => {
		setSelect((prev) => {
			if (prev.some((item) => item === val)) {
				return prev.filter((item) => item !== val);
			}
			return prev.concat([val]);
		});
	};

	const Scope = (props) => {
		return (
			data &&
			data.source_type
				.filter((value) => value.scope === parseInt(props.scopeId, 10))
				.map((value) => {
					return (
						<Fragment key={value.id}>
							<input
								size="sm"
								type="checkbox"
								className="btn-check d-none me-3"
								id={`scope_button-${value.id}`}
								variant="outline-secondary"
								autoComplete="off"
								checked={select.some((item) => item === value.id)}
								disabled={!result?.some((item) => item.id === value.id)}
								onChange={() => handleSelect(value.id)}
							/>

							<label className="btn btn-outline-secondary" htmlFor={`scope_button-${value.id}`}>
								{value.label}
							</label>
						</Fragment>
					);
				})
		);
	};

	return (
		<>
			<Row>
				<Col md={12} className="mb-5">
					<h5>Analizler</h5>
				</Col>
				<Col md={12} className="mb-5">
					<Form>
						<Row>
							<Col>
								<Form.Select size="sm" value={year} onChange={(e) => setYear(e.target.value)}>
									<option value={''}>Dönem Seçiniz</option>
									{years.map((item) => (
										<option value={item} key={item.id}>
											{item}
										</option>
									))}
								</Form.Select>
							</Col>
							<Col>
								<Form.Select defaultValue="Lokasyon Seçiniz" size="sm">
									<option>Lokasyon Seçiniz</option>
									<option>{factory[0].name}</option>
								</Form.Select>
							</Col>
							<Col className="d-flex justify-content-end">
								<Button variant="success" size="sm">
									Excel'e Aktar
								</Button>
							</Col>
							<Col>
								<Button variant="secondary" size="sm" disabled>
									Rapor Oluştur
								</Button>
							</Col>
						</Row>
					</Form>
				</Col>
				<Col md={12} lg={12} xl={8}>
					<Form>
						<Row>
							<Col lg={12} xl={12} className="mb-4">
								<Card>
									<Card.Header>
										<Card.Title>Kapsam 1 Emsiyonları</Card.Title>
									</Card.Header>
									<Card.Body>
										<div className="btn-group btn-group-sm" role="group">
											<Scope scopeId="1" />
										</div>
									</Card.Body>
								</Card>
							</Col>
							<Col lg={12} xl={12} className="mb-4">
								<Card>
									<Card.Header>
										<Card.Title>Kapsam 2 Emsiyonları</Card.Title>
									</Card.Header>
									<Card.Body>
										<div className="btn-group btn-group-sm" role="group">
											<Scope scopeId="2" />
										</div>
									</Card.Body>
								</Card>
							</Col>
							<Col lg={12} xl={12} className="mb-4">
								<Card>
									<Card.Header>
										<Card.Title>Kapsam 3 Emsiyonları</Card.Title>
									</Card.Header>
									<Card.Body>
										<div className="btn-group btn-group-sm" role="group">
											<Scope scopeId="3" />
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Form>
				</Col>
				<Col sm={12} md={12} lg={12} xl={4}>
					{chart.label.length === 0 && (
						<Alert variant="info">Henüz gösterilecek bir sonuç bulunmuyor.</Alert>
					)}
					{chart.label.length > 0 && (
						<>
							Sonuçlar
							<Pie
								redraw
								data={{
									labels: chart.label,
									datasets: [
										{
											label: 'Toplam Emisyon',
											data: chart.data,
											options: {
												maintainAspectRatio: false,
											},
										},
									],
								}}
							/>
						</>
					)}
					{chart.label.map((item, index) => {
						return (
							<div key={item}>
								{item}- {roundNumber(chart.data[index])} tCO2
							</div>
						);
					})}
				</Col>
			</Row>
		</>
	);
};

export default AnalysisScreen;
