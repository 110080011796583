import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createGlobalStyle } from 'styled-components';
import store from './store';
import { Provider } from 'react-redux';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  @font-face {
    font-family: Poppins-bold;
    src: url('/fonts/Poppins-Bold.ttf');
    font-weight:700
  }
  @font-face {
    font-family: Poppins-regular;
    src: url('/fonts/Poppins-Regular.ttf');
    font-weight:400
  }
  @font-face {
    font-family: Poppins-light;
    src: url('/fonts/Poppins-Light.ttf');
    font-weight:300
  }
  html,body{
    height:100%;
    font-size:14px;
    font-family:Poppins-regular;
    background-image: linear-gradient(to right top, #f1f1f1, #f2f3f2, #f4f4f4, #f5f6f5, #f7f7f7, #f8f8f9, #f9fafa, #fafbfa, #fbfcfc, #fcfdfd, #fefefe, #ffffff);
  }
  body{
    overflow-y: scroll;
  }
  #root{
    height:100%;
  }

  .card-header{
    background-image: linear-gradient(to right top, #343a40, #2e3338, #282c30, #232628, #1d1f21, #1e2022, #1e2123, #1f2224, #272b2d, #303536, #383f3f, #424948);
    color: #fff;
  }
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<GlobalStyle />
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
);
