import { createSlice } from '@reduxjs/toolkit';
import { registerUser, userLogin } from './authActions';
import jwtDecode from 'jwt-decode';

const initialState = {
	loading: false,
	userInfo: null,
	userToken: null,
	error: null,
	success: false,
	company: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			localStorage.removeItem('userToken'); // delete token from storage
			state.loading = false;
			state.userInfo = null;
			state.userToken = null;
			state.error = null;
			state.company = null;
		},
		setCredentials: (state, { payload }) => {
			state.userInfo = payload;
		},
	},
	extraReducers: (builder) => {
		// login user
		builder.addCase(userLogin.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(userLogin.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.userInfo = jwtDecode(payload.token);
			state.userToken = payload.token;
			state.company = jwtDecode(payload.token).company;
		});
		builder.addCase(userLogin.rejected, (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		});
		// register user
		builder.addCase(registerUser.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(registerUser.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.success = true; // registration successful
		});
		builder.addCase(registerUser.rejected, (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		});
	},
});

export const { logout, setCredentials } = authSlice.actions;

export default authSlice.reducer;
