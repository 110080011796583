import React from 'react';
import ScopeOneForm from './scopeOneForm';
import ScopeTwoForm from './scopeTwoForm';
import ScopeThreeForm from './scopeThreeForm';

const EmissionForm = ({ data, scopeId, scopeName, tableId }) => {
	return (
		<>
			{scopeId && scopeId === 1 && (
				<ScopeOneForm tableId={tableId} scopeId={scopeId} name={scopeName} data={data} />
			)}
			{scopeId && scopeId === 2 && (
				<ScopeTwoForm tableId={tableId} scopeId={scopeId} name={scopeName} data={data} />
			)}
			{scopeId && scopeId === 3 && (
				<ScopeThreeForm tableId={tableId} name={scopeName} data={data} />
			)}
		</>
	);
};

export default EmissionForm;
