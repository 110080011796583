import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import {
	useGetCompanyQuery,
	useGetUserDetailsQuery,
	useUpdateUserMutation,
} from '../../store/authService';

const ProfileScreen = () => {
	const { data } = useGetCompanyQuery('getCompany');

	// Update User
	const [updateUser, result] = useUpdateUserMutation();

	// Get UserInfo
	const { data: userInfo, isLoading, isSuccess } = useGetUserDetailsQuery('userDetails');

	const [user, setUser] = useState({
		name: '',
		email: '',
		phone: '',
	});

	const [errorShow, setShowError] = useState(false);

	useEffect(() => {
		setUser((prev) => ({
			...prev,
			name: userInfo?.user.name,
			email: userInfo?.user.email,
			phone: userInfo?.user.phone,
			btnDisabled: true,
		}));
	}, [userInfo]);

	const onChange = (e) => {
		if (e.target.value.length > 5) {
			setUser({ ...user, phone: e.target.value, btnDisabled: false });
			setShowError(false);
		} else {
			setUser({ ...user, btnDisabled: true });
			setShowError(true);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (user.phone.length > 5) {
			updateUser({
				id: userInfo?.user.id,
				phone: user.phone,
			});
			console.log(result);
		} else {
			setShowError(true);
		}
	};

	const ToastModal = () => {
		return (
			<ToastContainer position="bottom-end" style={{ zIndex: 1 }}>
				<Toast
					show={errorShow}
					onClose={() => setShowError(false)}
					delay={3000}
					autohide
					bg="danger"
				>
					<Toast.Body>Lütfen Doğru bir telefon numarası giriniz.</Toast.Body>
				</Toast>
			</ToastContainer>
		);
	};

	return (
		<>
			{errorShow && <ToastModal />}

			<Row>
				<Col className="text-end mb-4">
					<Button size="sm" variant="success">
						Kullanıcı Ekle {result.isLoading && result.isSuccess && 'Yükleniyor'}
					</Button>
				</Col>
				<Col lg={12} xl={12} className="mb-4">
					<Card>
						<Card.Header>Kullanıcı Bilgileri</Card.Header>
						<Card.Body>
							{isLoading && (
								<div className="d-flex align-items-center justify-content-center">
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
										className="me-4"
									/>
								</div>
							)}
							{isSuccess && data && (
								<Form onSubmit={handleSubmit}>
									<Row>
										<Form.Group as={Col} className="my-3" controlId="">
											<Form.Label>Kullanıcı Adı</Form.Label>
											<Form.Control size="sm" placeholder={user.name} value={user.name} disabled />
										</Form.Group>
										<Form.Group as={Col} className="my-3" controlId="">
											<Form.Label>E-Mail</Form.Label>
											<Form.Control size="sm" placeholder={user.email} disabled />
										</Form.Group>
										<Form.Group as={Col} className="my-3" controlId="">
											<Form.Label>Telefon</Form.Label>
											<Form.Control
												size="sm"
												placeholder={user.phone}
												onChange={onChange}
												name="phone"
											/>
										</Form.Group>
									</Row>
									<Row className="mt-4">
										<Col className="text-end">
											<Button variant="success" size="sm" type="submit" disabled={user.btnDisabled}>
												{result.isLoading && (
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
														className="me-4"
													/>
												)}
												{result.isLoading ? 'Güncelleniyor' : 'Güncelle'}
											</Button>
										</Col>
									</Row>
								</Form>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default ProfileScreen;
