import styled from 'styled-components';

const ResultContainer = styled.div`
	table {
		font-size: 0.8rem;
	}
`;
const SpinnerContainer = styled.div`
	height: 100%;
	background: rgba(255, 255, 255, 0.2);
	position: absolute;
	width: 100%;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export { ResultContainer, SpinnerContainer };
