import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../components/Layout';

const ProtectedRoute = ({ isProtected }) => {
	const { userToken } = useSelector((state) => state.auth);

	if (!isProtected) {
		return userToken ? <Navigate to="/" /> : <Outlet />;
	}

	if (!userToken) {
		return <Navigate to="/login" />;
	}

	return (
		<Layout>
			<Outlet />
		</Layout>
	);
};

export default ProtectedRoute;
