import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../actions/authActions';
import Spinner from 'react-bootstrap/Spinner';
import { Label, LoginContainer, Button, InputWrapper } from './styled';
import Logo from '../../components/Logo';

const LoginScreen = () => {
	const { loading } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const { register, handleSubmit } = useForm();

	const submitForm = (data) => {
		dispatch(userLogin(data));
	};

	return (
		<LoginContainer>
			<Logo color="#343a40" size="2rem" />
			<form onSubmit={handleSubmit(submitForm)} className="mt-5">
				<InputWrapper>
					<Label>Kullanıcı Adı</Label>
					<input type="text" {...register('email')} />
				</InputWrapper>
				<InputWrapper>
					<Label>Şifre</Label>
					<input type="password" {...register('password')} />
				</InputWrapper>
				<Button type="submit" disabled={loading}>
					{loading && (
						<Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
							className="me-4"
						/>
					)}
					{loading ? 'Yönlendiriliyor' : 'Giriş Yap'}
				</Button>
			</form>
			{/* {error && (
        <Alert key="danger" variant="danger">
          {error}
        </Alert>
      )} */}
			<div className="mt-4">
				<NavLink to="/forgot-password">Şifremi Unuttum</NavLink>
			</div>
		</LoginContainer>
	);
};

export default LoginScreen;
