import React from 'react';
import LoginScreen from './pages/Login';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomeScreen from './pages/home';
import ProfileScreen from './pages/Profile';
import ProtectedRoute from './routing/ProtectedRoute';
import LocationScreen from './pages/Location';
import CalculateScreen from './pages/Calculate';
import FirmScreen from './pages/FirmInfo';
import LogoutScreen from './pages/Logout';
import ResultScreen from './pages/Results';
import AnalysisScreen from './pages/Analysis';
import ForgotPassword from './pages/ForgotPassword';
import 'react-datepicker/dist/react-datepicker.css';
import ResetPassword from './pages/ResetPassword';

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/logout" element={<LogoutScreen />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/reset-password/:tokenURL" element={<ResetPassword />} />
				<Route element={<ProtectedRoute isProtected={false} />}>
					<Route path="/login" element={<LoginScreen />} />
				</Route>
				<Route element={<ProtectedRoute isProtected={true} />}>
					<Route path="/" element={<HomeScreen />} />
					<Route path="/location" element={<LocationScreen />} />
					<Route path="/user" element={<ProfileScreen />} />
					<Route path="/calculate" element={<CalculateScreen />} />
					<Route path="/firm-info" element={<FirmScreen />} />
					<Route path="/results" element={<ResultScreen />} />
					<Route path="/analysis" element={<AnalysisScreen />} />
				</Route>
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		</Router>
	);
}

export default App;
