import styled from 'styled-components';

const LogoutContainer = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

const ButtonContainer = styled.div`
	a {
		background: #343a40;
		min-width: 100px;
		padding: 12px 16px;
		color: white;
		cursor: pointer;
		border-radius: 5px;
		text-align: center;
		width: 100%;
		border: none;
		text-decoration: none;
	}
`;

export { LogoutContainer, ButtonContainer };
