import React, { useState } from 'react';
import { Row, Col, Container, Card, Button } from 'react-bootstrap';
import { useGetSourceTypeQuery, useGetEmissionSourceMutation } from '../../store/authService';
import EmissionForm from './form';
import Spinner from 'react-bootstrap/Spinner';
import ReportTable from './report';
import { GiElectric } from 'react-icons/gi';

const CalculateScreen = () => {
	const { data, isLoading } = useGetSourceTypeQuery();
	const [getEmissionSource, { data: emissionSourceData, isLoading: emissionSourceDataLoading }] =
		useGetEmissionSourceMutation();
	//const [sourceType, setSourceType] = useState();
	const [scope, setScope] = useState({ id: '', name: '' });
	const [scopeId, setScopeId] = useState();

	const getSourceId = async (id, scope, label) => {
		await getEmissionSource({ sourceId: id });
		setScopeId(id);
		setScope({ id: scope, name: label, tableId: id });
	};

	return (
		<>
			<Col md={12} lg={5} className="mb-4">
				<Card>
					<Card.Body>
						<Card.Title>Kapsam 1</Card.Title>
						<Card.Text>Doğrudan Sera Gazı Emisyonları</Card.Text>
						{isLoading && (
							<div className="d-flex align-items-center justify-content-center">
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
									className="me-4"
								/>
							</div>
						)}
						<Container>
							<div className="row g-3">
								{data &&
									data.source_type
										.filter((value) => value.scope === 1)
										.map((value) => {
											return (
												<div key={value.id} className="col col-md-6">
													<Button
														disabled={value.id === 12}
														className="h-100 w-100"
														variant={scopeId === value.id ? 'success' : 'outline-secondary'}
														size="sm"
														onClick={() => getSourceId(value.id, value.scope, value.label)}
													>
														{value.label}
													</Button>
												</div>
											);
										})}
							</div>
						</Container>
					</Card.Body>
				</Card>
			</Col>
			<Col md={12} lg={3} className="mb-4">
				<Card>
					<Card.Body>
						<Card.Title>Kapsam 2</Card.Title>
						<Card.Text>Enerji Dolaylı Sera Gazı Emisyonları</Card.Text>
						<div>
							{isLoading && (
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
									className="me-4"
								/>
							)}
						</div>
						{data &&
							data.source_type
								.filter((value) => value.scope === 2)
								.map((value) => {
									return (
										<Row className="mb-3" key={value.id}>
											<Col>
												<div className="d-grid gap-2">
													<Button
														variant={scopeId === value.id ? 'success' : 'outline-secondary'}
														size="sm"
														onClick={(e) => getSourceId(value.id, value.scope, value.label)}
													>
														<GiElectric /> {value.label}
													</Button>
												</div>
											</Col>
										</Row>
									);
								})}
					</Card.Body>
				</Card>
			</Col>
			<Col md={12} lg={4}>
				<Card>
					<Card.Body>
						<Card.Title>Kapsam 3</Card.Title>
						<Card.Text>Diğer Dolaylı Sera Gazı Emisyonları</Card.Text>
						<div>
							{isLoading && (
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
									className="me-4"
								/>
							)}
						</div>
						<Container>
							<Row className="g-4">
								{data &&
									data.source_type
										.filter((value) => value.scope === 3)
										.map((value) => {
											return (
												<div key={value.id} className="col col-md-6">
													<Button
														disabled
														className="h-100 w-100"
														variant={scopeId === value.id ? 'success' : 'outline-secondary'}
														size="sm"
														onClick={(e) => getSourceId(value.id, value.scope, value.label)}
													>
														{value.label}
													</Button>
												</div>
											);
										})}
							</Row>
						</Container>
					</Card.Body>
				</Card>
			</Col>
			<Row className="mt-5">
				<Col>
					{emissionSourceDataLoading && (
						<div className="d-flex align-items-center justify-content-center">
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
								className="me-4"
							/>
						</div>
					)}
					{emissionSourceData && (
						<>
							<p
								style={{ backgroundColor: '#343a40' }}
								className="h6 text-center text-white p-2 mb-5 rounded-1"
							>
								Hesaplama
							</p>
							<EmissionForm
								scopeName={scope.name}
								scopeId={scope.id}
								tableId={scope.tableId}
								data={emissionSourceData}
							/>
						</>
					)}
				</Col>
			</Row>
			{/* Report */}
			<ReportTable scope={scope.id} tableId={scope.tableId} scopeName={scope.name} />
		</>
	);
};

export default CalculateScreen;
