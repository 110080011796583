import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useAddEmissonResultMutation, useGetUnitListMutation } from '../../store/authService';
import jwtDecode from 'jwt-decode';
import Spinner from 'react-bootstrap/Spinner';
import { SpinnerContainer } from './styled';
import useScopeTransPortationForm from './useScopeTransPortationForm';

const ScopeThreeForm = ({ data, name }) => {
	const { userToken } = useSelector((state) => state.auth);
	const [unitlist, { data: unitListData }] = useGetUnitListMutation();
	const [addResult, { isLoading, isSuccess }] = useAddEmissonResultMutation();
	const [showModal, setShowModal] = useState(false);
	const form = useScopeTransPortationForm();
	const [factory, setFactory] = useState(null);

	useEffect(() => {
		setFactory(jwtDecode(userToken).company);
	}, [userToken]);

	const getUnitlist = async (e) => {
		await unitlist({ emissionSourceId: e.target.value });
		const emission = data.emission_source.find((ref) => ref.id === parseInt(e.target.value, 10));

		if (emission) {
			form.setEmissionFactorReferances(emission.referance_table);
			form.setEmissionFactor(emission.co2_default?.toFixed(5));
			form.setEmissionSource(emission.name);
		}

		form.setLocation(factory[0]?.city);
	};

	const submitForm = async (e) => {
		e.preventDefault();
		form.setEmissionScope(name);

		if (
			form.consumption_amount !== '' &&
			form.carbon_footprint !== '' &&
			form.emission_source !== '' &&
			form.unit !== '' &&
			form.period !== ''
		) {
			await addResult(form.getFormValues());
			form.reset();
			setShowModal(isSuccess);
			console.log('result', isLoading, isSuccess);
		} else {
			console.log('Doldur bu alanları');
			console.log(form.getFormValues());
		}
	};

	const handleClose = () => setShowModal(false);
	return (
		<Form className="position-relative" onSubmit={submitForm}>
			{isLoading && (
				<SpinnerContainer>
					<Spinner as="span" animation="border" size="sm" role="status" className="me-4" />
				</SpinnerContainer>
			)}
			<Row>
				<Form.Group as={Col} controlId="emission_source">
					<Form.Label>Emisyon Kaynağı</Form.Label>
					<Form.Select size="sm" name="emission_source" onChange={(e) => getUnitlist(e)}>
						<option>Seçiniz</option>;
						{data &&
							data.emission_source.map((value, index) => {
								return (
									<option key={index} value={value.id}>
										{value.name}
									</option>
								);
							})}
					</Form.Select>
				</Form.Group>

				<Form.Group as={Col} controlId="formGridEmail">
					<Form.Label>Birim</Form.Label>
					<Form.Select size="sm" name="unit" onChange={(e) => form.setUnit(e.target.value)}>
						<option>Seçiniz</option>;
						{unitListData &&
							unitListData.unit_type &&
							unitListData.unit_type.map((value, index) => {
								return (
									<option key={index} value={value.id}>
										{value.name}
									</option>
								);
							})}
					</Form.Select>
				</Form.Group>

				<Form.Group as={Col} controlId="amount">
					<Form.Label>Tüketim Miktarı</Form.Label>
					<Form.Control
						size="sm"
						name="consumption_amount"
						value={form.consumption_amount}
						onChange={(e) => form.setConsumptionAmount(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col} controlId="period">
					<Form.Label>Veri Giriş Periyodu</Form.Label>
					<Form.Check
						type="radio"
						label="Aylık"
						defaultValue="Aylık"
						name="period"
						id="month"
						onChange={(e) => form.setPeriod('Aylık')}
					/>
					<Form.Check
						type="radio"
						label="Yıllık"
						defaultValue="Yıllık"
						name="period"
						id="year"
						onChange={(e) => form.setPeriod('Yıllık')}
					/>
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col} controlId="ef" className="my-3">
					<Form.Label>EF(kgCO2e)</Form.Label>
					<Form.Control
						size="sm"
						name="emission_factor"
						value={form.emission_factor}
						onChange={(e) => form.setEmissionFactor(e.target.value)}
					/>
				</Form.Group>
				<Form.Group as={Col} className="my-3" controlId="explanation">
					<Form.Label>Açıklama</Form.Label>
					<Form.Control
						size="sm"
						name="explanation"
						onChange={(e) => form.setExplanation(e.target.value)}
					/>
				</Form.Group>
				<Form.Group as={Col} className="my-3" controlId="folder">
					<Form.Label>Dosya Ekle</Form.Label>
					<Form.Control size="sm" placeholder="" />
				</Form.Group>
			</Row>
			<Row>
				<Col>
					<Button variant="success" type="submit" disabled={isLoading}>
						Kaydet
					</Button>
				</Col>
			</Row>
			<Modal show={showModal} centered onHide={handleClose}>
				<Modal.Body>Kaydınız başarı ile alınmıştır</Modal.Body>
				<Modal.Footer>
					<Button size="sm" variant="danger" onClick={handleClose}>
						Kapat
					</Button>
				</Modal.Footer>
			</Modal>
		</Form>
	);
};

export default ScopeThreeForm;
