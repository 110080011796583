import { logout } from '../actions/authSlice';

const AuthMiddleware = (store) => (next) => (action) => {
	if (action.payload) {
		if (action.payload.originalStatus === 401) {
			store.dispatch(logout());
		}
	}
	return next(action);
};

export default AuthMiddleware;
