import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//const baseUrl = 'http://localhost:3000';
const baseUrl = 'https://carbon-backend-6eo4.onrender.com';

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().auth.userToken;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
				return headers;
			}
		},
	}),
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		getUserDetails: build.query({
			query: () => ({
				url: 'getuser',
				method: 'GET',
			}),
		}),
		getSourceType: build.query({
			query: () => ({
				url: 'source-type',
				method: 'GET',
			}),
		}),
		getEmissionSource: build.mutation({
			query: (sourceId) => ({
				url: 'emission-source',
				method: 'POST',
				body: sourceId,
			}),
		}),
		getUnitList: build.mutation({
			query: (emissionSourceId) => ({
				url: 'unit-list',
				method: 'POST',
				body: emissionSourceId,
			}),
		}),
		getCompany: build.query({
			query: () => ({
				url: 'company',
				method: 'GET',
			}),
		}),
		addEmissonResult: build.mutation({
			query: (data) => ({
				url: 'result',
				method: 'POST',
				body: data,
			}),
		}),
		getEmissonResult: build.query({
			query: (data) => ({
				url: 'get-result',
				method: 'POST',
				body: data,
			}),
		}),
		getAllResult: build.query({
			query: ({ year, month }) => ({
				url: `all-result`,
				method: 'GET',
				params: {
					year,
					month,
				},
			}),
		}),
		updateUser: build.mutation({
			query: (data) => ({
				url: 'update-user',
				method: 'POST',
				body: data,
			}),
		}),
		updateCompany: build.mutation({
			query: (data) => ({
				url: 'update-company',
				method: 'POST',
				body: data,
			}),
		}),
		forgotPassword: build.mutation({
			query: (data) => ({
				url: 'forgot-password',
				method: 'POST',
				body: data,
			}),
		}),
		deleteResult: build.mutation({
			query: (id) => ({
				url: 'delete-result',
				method: 'POST',
				body: { id },
			}),
		}),
		getEmissonResultForChart: build.query({
			query: () => ({
				url: 'get-result-for-chart',
				method: 'GET',
			}),
		}),
		getEmissonResultForAnalysis: build.query({
			query: ({ year }) => ({
				url: 'get-result-for-analysis',
				method: 'GET',
				params: {
					year,
				},
			}),
		}),
		resetPassword: build.mutation({
			query: (data) => ({
				url: 'reset-password',
				method: 'POST',
				body: data,
			}),
		}),
	}),
});

// export react hook
export const {
	useGetUserDetailsQuery,
	useGetSourceTypeQuery,
	useGetEmissionSourceMutation,
	useGetUnitListMutation,
	useGetCompanyQuery,
	useAddEmissonResultMutation,
	useGetEmissonResultQuery,
	useGetAllResultQuery,
	useUpdateUserMutation,
	useUpdateCompanyMutation,
	useForgotPasswordMutation,
	useDeleteResultMutation,
	useGetEmissonResultForChartQuery,
	useGetEmissonResultForAnalysisQuery,
	useResetPasswordMutation,
} = authApi;
