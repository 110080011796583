import { useState } from 'react';

const useScopeTwoForm = () => {
	const [location, setLocation] = useState('');
	const [emission_scope, setEmissionScope] = useState('');
	const [emission_source, setEmissionSource] = useState('');
	const [consumption_amount, setConsumptionAmount] = useState('');
	const [unit, setUnit] = useState('');
	const [co2, setCo2] = useState('');
	const [ch4, setCh4] = useState('');
	const [n20, setN20] = useState('');
	const [emission_factor, setEmissionFactor] = useState('');
	const [explanation, setExplanation] = useState('');
	const [emission_factor_referances, setEmissionFactorReferances] = useState('');
	const [period, setPeriod] = useState('');

	const reset = () => {
		setLocation('');
		setEmissionScope('');
		setEmissionSource('');
		setConsumptionAmount('');
		setUnit('');
		setCo2('');
		setCh4('');
		setN20('');
		setEmissionFactor('');
		setExplanation('');
		setEmissionFactorReferances('');
		setPeriod('');
	};

	const getFormValues = () => {
		return {
			location,
			emission_scope,
			emission_source,
			consumption_amount,
			unit,
			co2,
			ch4,
			n20,
			emission_factor,
			carbon_footprint: consumption_amount * emission_factor,
			explanation,
			emission_factor_referances,
			period,
		};
	};

	return {
		location,
		setLocation,
		emission_scope,
		setEmissionScope,
		emission_source,
		setEmissionSource,
		consumption_amount,
		setConsumptionAmount,
		unit,
		setUnit,
		co2,
		setCo2,
		ch4,
		setCh4,
		n20,
		setN20,
		emission_factor,
		setEmissionFactor,
		explanation,
		setExplanation,
		emission_factor_referances,
		setEmissionFactorReferances,
		period,
		setPeriod,
		reset,
		getFormValues,
	};
};

export default useScopeTwoForm;
