import styled from 'styled-components';

const NavbarContainer = styled.div`
	background: transparent;
	margin-top: 100px;

	a {
		text-decoration: none;
		color: #fff;
		margin-bottom: 25px;
		font-weight: 700;
	}
`;

export { NavbarContainer };
