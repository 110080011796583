import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
	useAddEmissonResultMutation,
	useGetEmissonResultQuery,
	useGetUnitListMutation,
} from '../../store/authService';
import jwtDecode from 'jwt-decode';
import Spinner from 'react-bootstrap/Spinner';
import { DatePickerContainer, SpinnerContainer } from './styled';
import useScopeTwoForm from './useScopeTwoForm';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const ScopeTwoForm = ({ data, name, tableId }) => {
	const { userToken } = useSelector((state) => state.auth);
	const [unitlist, { data: unitListData }] = useGetUnitListMutation();
	const [addResult, { isLoading, isSuccess, isError }] = useAddEmissonResultMutation();
	const { refetch } = useGetEmissonResultQuery({ source_id: tableId }, { skip: !tableId });
	const form = useScopeTwoForm();
	const [factory, setFactory] = useState(null);
	const [modalMessage, setMessage] = useState();
	const [file, setFile] = useState(null);

	useEffect(() => {
		setFactory(jwtDecode(userToken).company);
	}, [userToken]);

	useEffect(() => {
		if (isSuccess) {
			setMessage('Emisyon Kaydınız başarı ile kayıt altına alınmıştır.');
			form.reset();
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			setMessage('Emisyon Kaydı alınamadı.');
		}
	}, [isError]);

	useEffect(() => {
		if (form.emissionSourceID) {
			getUnitlist(form.emissionSourceID);
		}
	}, [form.emissionSourceID]);

	const handleClose = () => {
		setMessage();
	};

	const getUnitlist = async (emissionSourceId) => {
		await unitlist({ emissionSourceId });
		const emission = data.emission_source.find((ref) => ref.id === parseInt(emissionSourceId, 10));

		if (emission) {
			console.log('emission:', emission);
			form.setEmissionFactorReferances(emission.referance_table);
			form.setEmissionFactor(emission.co2_default.toFixed(2));
			form.setEmissionSource(emission.name);
			form.setEmissionScope(name);
		}
		form.setLocation(factory[0]?.city);
	};

	const submitForm = async (e) => {
		e.preventDefault();

		if (
			form.consumption_amount !== '' &&
			form.carbon_footprint !== '' &&
			form.emissionSourceID !== undefined &&
			form.unit !== '' &&
			form.period !== '' &&
			form.emission_scope !== '' &&
			form.emission_date !== ''
		) {
			await addResult({ ...form.getFormValues(), emission_file: file });
			refetch();
		} else {
			setMessage('Lütfen boş alanları doldurunuz.');
		}
	};

	const renderMonthContent = (month, shortMonth, longMonth, day) => {
		const fullYear = new Date(day).getFullYear();
		const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

		return <span title={tooltipText}>{shortMonth}</span>;
	};

	const getBase64 = (file) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			setFile(reader.result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	};

	const handleChange = (e) => {
		if (e.target.files.length > 0) {
			getBase64(e.target.files[0]);
		}
	};

	return (
		<Form className="position-relative" onSubmit={submitForm}>
			{isLoading && (
				<SpinnerContainer>
					<Spinner as="span" animation="border" size="sm" role="status" className="me-4" />
				</SpinnerContainer>
			)}

			<Row>
				<Form.Group as={Col} controlId="emission_source">
					<Form.Label>Emisyon Kaynağı</Form.Label>
					<Form.Select
						size="sm"
						name="emission_source"
						value={form.emissionSourceID || ''}
						onChange={(e) => form.setEmissionSourceID(e.target.value)}
					>
						<option>Seçiniz</option>
						{data &&
							data.emission_source.map((value, index) => {
								return (
									<option key={index} value={value.id}>
										{value.name}
									</option>
								);
							})}
					</Form.Select>
				</Form.Group>

				<Form.Group as={Col} controlId="amount">
					<Form.Label>Tüketim Miktarı</Form.Label>
					<Form.Control
						size="sm"
						name="consumption_amount"
						value={form.consumption_amount}
						onChange={(e) => form.setConsumptionAmount(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col} controlId="ef">
					<Form.Label>EF(kgCO2e)</Form.Label>
					<Form.Control
						size="sm"
						name="emission_factor"
						value={form.emission_factor}
						onChange={(e) => form.setEmissionFactor(e.target.value)}
					/>
				</Form.Group>

				<Form.Group as={Col} controlId="formGridEmail">
					<Form.Label>Birim</Form.Label>
					<Form.Select
						size="sm"
						name="unit"
						value={form.unit}
						onChange={(e) => form.setUnit(e.target.value)}
					>
						<option>Seçiniz</option>;
						{unitListData &&
							unitListData.unit_type &&
							unitListData.unit_type.map((value, index) => {
								return (
									<option key={index} value={value.id}>
										{value.name}
									</option>
								);
							})}
					</Form.Select>
				</Form.Group>
				<Form.Group as={Col} controlId="period">
					<Form.Label>Veri Giriş Periyodu</Form.Label>
					<Row>
						<Col>
							<Form.Check
								type="radio"
								label="Aylık"
								checked={form.period === 'Aylık'}
								name="period"
								id="month"
								onChange={(e) => form.setPeriod('Aylık')}
							/>
						</Col>
						<Col>
							<Form.Check
								type="radio"
								label="Yıllık"
								checked={form.period === 'Yıllık'}
								name="period"
								id="year"
								onChange={(e) => form.setPeriod('Yıllık')}
							/>
						</Col>
						<Col size={12}>
							<DatePickerContainer>
								<DatePicker
									selected={moment(form.emission_date).toDate()}
									renderMonthContent={renderMonthContent}
									showMonthYearPicker={form.period === 'Aylık'}
									showYearPicker={form.period === 'Yıllık'}
									dateFormat={form.period === 'Aylık' ? 'MM/yyyy' : 'yyyy'}
									onSelect={(e) => form.emission_setDate(e)}
								/>
							</DatePickerContainer>
						</Col>
					</Row>
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col} className="my-3" controlId="explanation">
					<Form.Label>Açıklama</Form.Label>
					<Form.Control
						size="sm"
						name="explanation"
						value={form.explanation}
						onChange={(e) => form.setExplanation(e.target.value)}
					/>
				</Form.Group>
				<Form.Group as={Col} className="my-3" controlId="file">
					<Form.Label>Dosya Ekle</Form.Label>
					<Form.Control
						name="file"
						type="file"
						size="sm"
						value={form.file}
						onChange={(e) => handleChange(e)}
					/>
				</Form.Group>
			</Row>
			<Row>
				<Col>
					<Button variant="success" type="submit" disabled={isLoading}>
						{isLoading && (
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
								className="me-4"
							/>
						)}
						Kaydet
					</Button>
				</Col>
			</Row>
			<Modal show={modalMessage !== undefined} centered onHide={handleClose}>
				<Modal.Body className="text-center">{modalMessage}</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button size="sm" variant="danger" onClick={handleClose}>
						Kapat
					</Button>
				</Modal.Footer>
			</Modal>
		</Form>
	);
};

export default ScopeTwoForm;
