import React, { useEffect } from 'react';
import { Navbar, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '../../store/authService';
import { setCredentials } from '../../actions/authSlice';
import { NavLink } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';

const NavBar = () => {
	const { userInfo } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	// automatically authenticate user if token is found
	const { data, isFetching } = useGetUserDetailsQuery('userDetails', {
		pollingInterval: 900000, // 15mins
	});

	useEffect(() => {
		if (data) dispatch(setCredentials(data));
	}, [data, dispatch]);

	return (
		<Container fluid className="mb-5 mt-3 sticky-top">
			<Row>
				<Navbar variant="bg-light" className="bg-body-tertiary border rounded px-3">
					<Navbar.Brand href="#home">Eren Socks</Navbar.Brand>
					<Navbar.Toggle />
					<Navbar.Collapse className="justify-content-end">
						<Navbar.Text style={{ fontSize: '0.9rem' }}>
							<span className="fw-bold">Giriş Yapan:</span>
							{!isFetching && userInfo && userInfo.user
								? ` ${userInfo.user.email}`
								: ' İsim gelmedi'}
						</Navbar.Text>
						<NavLink className="ms-2" size="sm" to="/logout">
							<FiLogOut />
						</NavLink>
					</Navbar.Collapse>
				</Navbar>
			</Row>
		</Container>
	);
};

export default NavBar;
