import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Tab, Nav } from 'react-bootstrap';
import { useGetCompanyQuery, useUpdateCompanyMutation } from '../../store/authService';

const LocationScreen = () => {
	const [firm, setFirm] = useState();
	const { data, isLoading, isSuccess } = useGetCompanyQuery();

	useEffect(() => {
		setFirm(data?.company);
	}, [data]);

	console.log('da', data);

	const Factory = (props) => {
		return (
			<Form>
				<Form.Group as={Col} controlId="formGridEmail">
					<Form.Label>Ülke</Form.Label>
					<Form.Control type="text" placeholder={props.data && props.data.country} />
				</Form.Group>

				<Form.Group as={Col} controlId="formGridPassword">
					<Form.Label>Şehir</Form.Label>
					<Form.Control type="text" placeholder={firm && firm.city} />
				</Form.Group>

				<Form.Group className="mb-3" controlId="formGridAddress1">
					<Form.Label>Address</Form.Label>
					<Form.Control placeholder={firm && firm.address} />
				</Form.Group>

				<Form.Group as={Col} controlId="formGridEmail">
					<Form.Label>Çalışan Sayısı</Form.Label>
					<Form.Control type="text" placeholder={firm && firm.workers_count} />
				</Form.Group>

				<Form.Group as={Col} controlId="formGridPassword">
					<Form.Label>Ciro</Form.Label>
					<Form.Control type="text" placeholder={firm && firm.fee} />
				</Form.Group>

				<Form.Group as={Col} controlId="formGridPassword">
					<Form.Label>Üretilen Ürün Miktarı</Form.Label>
					<Form.Control type="text" placeholder={firm && firm.product_count} />
				</Form.Group>

				<Form.Group className="mt-3" controlId="formGridAddress1">
					<Button size="sm" variant="success" type="submit">
						Kaydet
					</Button>
				</Form.Group>
			</Form>
		);
	};
	return (
		<Tab.Container
			onSelect={(e) => (e === 'first' ? setFirm(data.company) : setFirm(data.releation_company))}
			id="left-tabs-example"
			defaultActiveKey="first"
		>
			<Row>
				<Col lg={4}>
					<Nav variant="underline" className="flex-column">
						<Nav.Item>
							<Nav.Link eventKey="first">{data?.company.name}</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="second">{data?.releation_company?.name}</Nav.Link>
						</Nav.Item>
					</Nav>
				</Col>
				<Col>
					<Card>
						<Card.Header>Lokasyon Bilgileri</Card.Header>
						<Card.Body>
							<Tab.Content>
								<Tab.Pane eventKey="first">
									<Factory data={firm} />
								</Tab.Pane>
								<Tab.Pane eventKey="second">
									<Factory data={firm} />
								</Tab.Pane>
							</Tab.Content>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Tab.Container>
	);
};

export default LocationScreen;
