import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LeftNavbar from '../LetfNavbar';
import NavBar from '../Navbar';

const Layout = ({ children }) => {
	return (
		<Container fluid className="h-100">
			<Row className="h-100 ">
				<Col
					md={2}
					className="fixed-top"
					style={{
						height: '98%',
						backgroundImage:
							'linear-gradient(to right top, #343a40, #2e3338, #282c30, #232628, #1d1f21, #1e2022, #1e2123, #1f2224, #272b2d, #303536, #383f3f, #424948)',
						borderRadius: 10,
						margin: '10px',
						//background: '#fff',
					}}
				>
					<LeftNavbar />
				</Col>
				<Col md={10} className="offset-md-2 px-5">
					<NavBar />
					<Row>{children}</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default Layout;
