import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Button, Spinner, Form, Tabs, Tab, Alert } from 'react-bootstrap';
import { useGetAllResultQuery } from '../../store/authService';
import { ResultContainer, SpinnerContainer } from './styled';
import { CSVLink, CSVDownload } from 'react-csv';
import moment from 'moment';
import 'moment/locale/tr';
import { roundNumber } from '../../utils';

moment.locale('tr');

const months = moment.months();

const ResultScreen = () => {
	const [year, setYear] = useState(new Date().getFullYear());
	const [month, setMonth] = useState('');

	const {
		data: { result, years } = { result: [], years: [] },
		isLoading,
		isSuccess,
	} = useGetAllResultQuery(
		{
			year: year === '' ? undefined : year,
			month: month === '' ? undefined : month,
		},
		{
			refetchOnMountOrArgChange: true,
		},
	);
	const csv = useMemo(() => {
		if (result && isSuccess) {
			return result.map((item) => ({
				...item,
				emission_factor: roundNumber(item.emission_factor),
				carbon_footprint: roundNumber(item.carbon_footprint),
				emission_date: moment(item.emission_date).format('DD/MM/YYYY'),
			}));
		}
		return [];
	}, [result, isSuccess]);

	let totalCarbon =
		isSuccess &&
		result.reduce((total, arr) => {
			return total + parseFloat(arr.carbon_footprint);
		}, 0);

	const ResultView = () => {
		return result.map((value, index) => {
			return (
				<tr key={index}>
					<td>{value.location}</td>
					<td>{value.emission_scope}</td>
					<td>{value.emission_source}</td>
					<td>{value.consumption_amount}</td>
					<td>{value.unit}</td>
					<td>{value.co2}</td>
					<td>{value.ch4}</td>
					<td>{value.n20}</td>
					<td>{roundNumber(value.emission_factor)} kgCO2/kWh</td>
					<td>{roundNumber(value.carbon_footprint)} ton</td>
					<td>{value.explanation}</td>
					<td>{value.emission_factor_referances}</td>
				</tr>
			);
		});
	};

	const headers = [
		{ label: 'Lokasyon', key: 'location' },
		{ label: 'Emisyon Kapsamı', key: 'emission_scope' },
		{ label: 'Emsiyon Kaynağı', key: 'emission_source' },
		{ label: 'Tüketim Miktarı', key: 'consumption_amount' },
		{ label: 'Birim', key: 'unit' },
		{ label: 'tCo2', key: 'c02' },
		{ label: 'tCh4', key: 'ch4' },
		{ label: 'tN20', key: 'n20' },
		{ label: 'Emisyon Faktörü', key: 'emission_factor' },
		{ label: 'Karbon Ayak İzi', key: 'carbon_footprint' },
		{ label: 'Açıklama', key: 'explanation' },
		{ label: 'Emisyon Farktörü Referansı', key: 'emission_factor_referances' },
		{ label: 'Date', key: 'emission_date' },
	];

	return (
		<ResultContainer>
			<Row>
				<Col className="mb-5">
					<Form>
						<Row>
							<Col>
								<Form.Select size="sm" value={year} onChange={(e) => setYear(e.target.value)}>
									<option value={''}>Dönem Seçiniz</option>
									{years.map((year) => (
										<option value={year} key={year}>
											{year}
										</option>
									))}
								</Form.Select>
							</Col>
							<Col>
								<Form.Select size="sm" value={month} onChange={(e) => setMonth(e.target.value)}>
									<option value={''}>Ay Seçiniz</option>
									{months.map((month, index) => (
										<option value={index + 1} key={month}>
											{month}
										</option>
									))}
								</Form.Select>
							</Col>
							<Col>
								<Form.Select defaultValue="Lokasyon Seçiniz" size="sm">
									<option>Lokasyon Seçiniz</option>
									<option>İstanbul</option>
								</Form.Select>
							</Col>
							<Col className="d-flex justify-content-end">
								{csv === undefined ? (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
										className="me-4"
									/>
								) : (
									<CSVLink
										filename="keyofcarbon-rapor"
										headers={headers}
										data={csv}
										className="btn btn-sm btn-success"
										separator=";"
									>
										Excel'e Aktar
									</CSVLink>
								)}
							</Col>
							<Col>
								<Button variant="secondary" size="sm" disabled>
									Rapor Oluştur
								</Button>
							</Col>
						</Row>
					</Form>
				</Col>
				<Col lg={12} xl={12} className="mb-4">
					<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
						<Tab eventKey="home" title=" Karbon Ayaka İzi">
							{isLoading && (
								<SpinnerContainer>
									<Spinner as="span" animation="border" size="sm" role="status" className="me-4" />
								</SpinnerContainer>
							)}
							{isSuccess && (
								<div className="table-responsive">
									<table className="table table-striped table-hover">
										<thead>
											<tr>
												<th scope="col">Lokasyon</th>
												<th scope="col">Emisyon Kapsamı</th>
												<th scope="col">Emisyon Kaynağı</th>
												<th scope="col">Tüketim Miktarı</th>
												<th scope="col">Birim </th>
												<th scope="col">tCO2</th>
												<th scope="col">tCH4</th>
												<th scope="col">tN20</th>
												<th scope="col">Emisyon Faktörü</th>
												<th scope="col">Karbon Ayak İzi</th>
												<th scope="col">Açıklama</th>
												<th scope="col">Emisyon Faktörü Referansı</th>
											</tr>
										</thead>
										<tbody>
											{isLoading ? (
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
													className="me-4"
												/>
											) : (
												<ResultView />
											)}
										</tbody>
									</table>
								</div>
							)}
							{result.length === 0 && (
								<Alert variant="info">Henüz gösterilecek bir veriniz bulunmamaktadır.</Alert>
							)}
							<Row>
								<Col className="text-end">
									<p className="fw-bold color-dark">Toplam tCO2: {roundNumber(totalCarbon)}</p>
								</Col>
							</Row>
						</Tab>
						<Tab eventKey="profile" title="Enerji Yönetimi ve Berlirsizlikler" disabled>
							Tab content for Profile
						</Tab>
						<Tab eventKey="contact" title="Geçmiş" disabled>
							Geçmiş
						</Tab>
					</Tabs>
				</Col>
			</Row>
		</ResultContainer>
	);
};

export default ResultScreen;
